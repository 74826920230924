import { React, useState, useEffect } from "react";
import adminApiService from "../../services/admiApis.service";
import { Box, Toolbar, Typography, FormControl, InputLabel, Select, MenuItem, Card, Grid, TextField, Paper, Button } from "@mui/material";

import notify from "../notification/notification";
import Dashboard from "../dashboard";

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
        Add Referral Code
      </Typography>
    </Toolbar>
  );
};

export default function AddRefferalCode() {
  const [vechileType, setVehicleType] = useState("");
  const [price, setPrice] = useState(null);
  const [validityInMonth, setValidityInMonth] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const res = await adminApiService.getVechileType();
      if (res.success) {
        setOptions(res.response);
      } else {
        setOptions([]);
      }
    }

    fetchData().catch(console.error);
  }, []);

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleValidityInMonth = (event) => {
    setValidityInMonth(event.target.value);
  };

  const createRefferal = async (e) => {
    e.preventDefault();
    if (!vechileType || !price || !validityInMonth) {
      alert("Please enter the price or validity or vehicle type.");
      return;
    }
    const payload = {
      vechile_type: vechileType,
      price: price,
      validity_in_month: validityInMonth,
    };
    try {
      const res = await adminApiService.addReferralCode(payload);
      if (res.success) {
        notify.sucess("Added Referral Successfully");
        setVehicleType(null);
        setPrice(null);
        setValidityInMonth(null);
      } else {
        notify.error("Failed");
      }
    } catch (err) {
      notify.error("Failed");
    }
  };

  const handleDropDownChange = (event) => {
    setVehicleType(event.target.value);
  };

  return (
    <>
      <Dashboard>
        <Box className="content-wrapper" mt={2}>
          <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
            <EnhancedTableToolbar />
            <Card className="mt-2">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                  <TextField id="standard-basic" label="Price" name="price" type="number" onChange={handlePriceChange} sx={{ width: "100%" }} />
                </Grid>

                <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                  <TextField
                    id="standard-basic"
                    label="Validity (in months)"
                    name="validityInMonth"
                    type="number"
                    InputProps={{ min: "1", max: "12" }}
                    onChange={handleValidityInMonth}
                    sx={{ width: "100%" }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Vehicle Type</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" name="vechile_type" value={vechileType} label="Vehicle Type" onChange={handleDropDownChange}>
                      {options.length > 0 &&
                        options.map((ele) => {
                          return (
                            <MenuItem key={ele.id} value={ele.vechile_type}>
                              {ele.vechile_type}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Typography align="left" sx={{ mt: 6, mb: 6, ml: 2 }}>
                <Button type="submit" variant="contained" color="primary" size="large" onClick={createRefferal}>
                  Create Refferal
                </Button>
              </Typography>
            </Card>
          </Paper>
        </Box>
      </Dashboard>
    </>
  );
}
