import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useAuthState } from "../../context";

const AppRoutes = ({ component: Component, path, exact, isPrivate, ...rest }) => {
  const userAdmin = useAuthState();
  return <Route path={path} exact={exact} render={(props) => (isPrivate && !Boolean(userAdmin.name) ? <Redirect to={{ pathname: "/login" }} /> : <Component {...props} />)} {...rest} />;
};

export default AppRoutes;
