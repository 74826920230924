let name = localStorage.getItem('adminUser') ? JSON.parse(localStorage.getItem('adminUser')).name : '';

export const initialState = {
  name: '' || name,
  loading: false,
  errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...initialState,
        loading: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...initialState,
        name: action.payload.name,
        email: action.payload.email,
        loading: false,
      };
    case 'LOGOUT':
      return {
        ...initialState,
        name: '',
        email: '',
      };

    case 'LOGIN_ERROR':
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
