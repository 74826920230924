import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Toolbar from "@mui/material/Toolbar";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Multiselect } from "multiselect-react-dropdown";

import Dashboard from "../dashboard";
import adminApiService from "../../services/admiApis.service";
import notify from "../notification/notification";

import { useHistory, useParams, useLocation } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedQRCode, theme) {
  return {
    fontWeight:
      selectedQRCode.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AssignQRCode() {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const { state } = location;
  const theme = useTheme();
  const [qrList, setQRList] = useState([]);
  const [selectedQRCode, setSelectedQRCode] = useState('');
  const [vehicleData, setVehicleData] = useState("");
  // let vehicleNumbers =[];
  // console.log("state", state);

  // useEffect(async () => {
  //   try {
  //     const res = await adminApiService.getInactiveQRCodes({ status: null });
  //     console.log(res);
  //     if (res.success) {
  //       setQRList(res.response);
  //     } else {
  //       setQRList([]);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, []);

  const handleLag = async (event) => {
    if (event != "") {
      try {
        const res = await adminApiService.getInactiveQRCodes({
          status: null,
          qr_code_id: event,
        });
        // console.log(res);
        if (res.success) {
          setQRList(res.response);
        } else {
          setQRList([]);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setQRList([]);
    }
  };

  let lag;

  const handleQrList = (event) => {
    // console.log("event",event);

    clearTimeout(lag);
    lag = setTimeout(() => handleLag(event), 1000);
  };
 // console.log("dfdgfgf",selectedQRCode);
  const handleChange = (event) => {
    // console.log(event);
    // const {
    //   target: { value },
    // } = event;
    let data = event.map((item) => item.qr_code_id);
    //  console.log(selectedQRCode);
    //  console.log(data);

    setSelectedQRCode(data);
    // setSelectedQRCode(typeof value === "string" ? value.split(",") : value);
  };

  const updateOrder = async (event) => {
    if (selectedQRCode.length === 0) {
      alert("Please Assign QR Code");
      return;
    }

    const data = {
      order_qr_id: selectedOption,
      qr_code_id: Number(selectedQRCode),
    };

    if (data.qr_code_id.length > state.quantity) {
      alert(`Maximum assign QR Code to be ${state.quantity}`);
      return;
    }

    const res = await adminApiService.addQRIntoOrder(data);
    if (res.success) {
      notify.sucess("QR Code Assign Successfully");
      history.push("/customerOrder");
    } else {
      notify.error(res.message);
    }
  };

  const Sdata = [
    { country: "51", id: 1 },
    { country: "36", id: 1 },
    { country: "22", id: 1 },
    { country: "24", id: 1 },
    { country: "85", id: 1 },
  ];

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    setRole(JSON.parse(localStorage.getItem("adminUser")).role);
  }, []);

  // const [options]=useState(Sdata);
  const [enterQr, setEnterQr] = useState("");

  const handleEnterQr = (event) => {
    setEnterQr(event.target.value.trim());
  };
  
  const handleOptionChange = (event) => {
   // console.log(event.target.value);
    setSelectedOption(event.target.value);
   
  };

  const updateQrOrder = async (event) => {
    if (!enterQr) {
      alert("Please enter the QR Code ID");
      return;
    }
    var regex = /^[0-9\s]*$/;
    var isValid = regex.test(enterQr);

    if (!isValid) {
      alert("Enter only numbers");
      return false;
    }
    var qr = enterQr.split(" ").map((item) => Number(item));

    const data = {
      order_qr_id:selectedOption,
      qr_code_id: qr,
    };
    // console.log(state.quantity);
    if (data.qr_code_id.length > state.quantity) {
      alert(`Maximum assign QR Code to be ${state.quantity}`);
      return;
    }

    const res = await adminApiService.addQRIntoOrder(data);
    if (res.success) {
      notify.sucess("QR Code Assign Successfully");
      history.push("/order");
    } else {
      notify.error(res.message);
    }
  };

  // const fetchVehicleNumbers = async () => {
  //   try {
  //     const res = await adminApiService.getVehicleNumbers(state.id);
  //     console.log('API Response:', res); // Log the API response
  //     console.log("===",res.success)
  //     if (res.success == true) {

  //       console.log("===",res.success)
  //       vehicleNumbers = res.map((vehicle) => vehicle.vechile_no);
  //       setOptions(vehicleNumbers);
  //       console.log(setOptions)
  //       console.log("jj",vehicleNumbers)
  //     } else {
  //       notify.error(res ? res.message : 'Unknown error');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching vehicle numbers:', error);
  //     // Handle the error appropriately...
  //   }
  // };
  // const fetchVehicleNumbers = async () => {
  //   try {
  //     const res = await adminApiService.getVehicleNumbers(state.id);
  //     console.log('API Response:', res); // Log the API response
  //     console.log("Success:", res.success);
  //     console.log('Success:', res.success, typeof res.success);
  //     if (res.success === true ) {
  //       console.log("Success:", res.success);
  //       vehicleNumbers = res.data ? res.data.map((vehicle) => vehicle.vechile_no) : [];
  //       setOptions(vehicleNumbers);
  //       console.log("Options:", vehicleNumbers);
  //     } else {
  //       notify.error(res ? res.message : 'Unknown error');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching vehicle numbers:', error);
  //     // Handle the error appropriately...
  //   }
  // };

  const fetchVehicleNumbers = async () => {
    try {
      const res = await adminApiService.getVehicleNumbers(state.id);
    //  console.log("API Response:", res);

      if (res.success === true) {
        let vehicleNumbers;
        setVehicleData(res);

        if (Array.isArray(res)) {
          vehicleNumbers = res.response.map((vehicle) => vehicle.vechile_no);
          setOptions(vehicleNumbers);
        //  console.log("Options:", vehicleNumbers);
        } else if (res == undefined && res == null) {
          vehicleNumbers.push(res.response.vechile_no);
        }

        // setOptions(vehicleNumbers);
      //  console.log("Options:", vehicleNumbers);
      } else {
        notify.error(res ? res.message : "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching vehicle numbers:", error);
      // Handle the error appropriately...
    }
  };
 // console.log("000", vehicleData);
  useEffect(() => {
    fetchVehicleNumbers();
  }, [state.id]);

  return (
    <Dashboard>
      <div className="update-order-container">
        <Card sx={{ pb: 16 }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Assign QR Code
            </Typography>
          </Toolbar>
          <Grid container spacing={3} sx={{ pl: 2 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Vehicle No / chassis No
                </InputLabel>
                {/* <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedOption}
                  label="Select Option"
                  onChange={handleOptionChange}
                >
               
                  {vehicleData?.response?.length > 0
                        ? vehicleData.response?.map((option) => (
                            <MenuItem
                              key={option?.order_qr_Id}
                              value={option?.order_qr_Id}
                            >
                              <span style={{ fontFamily: "Roboto" }}>
                                {option?.vechile_no}
                              </span>
                            </MenuItem>
                          ))
                        : []}
                </Select> */}

<Select value={selectedOption} onChange={handleOptionChange}>
  {vehicleData?.response?.length > 0 ? (
    vehicleData?.response?.map((option) => (
      <MenuItem key={option?.order_qr_id} value={option?.order_qr_id}>
        <span style={{ fontFamily: "Roboto" }}> vehicle No: {option?.vechile_no} / chassis No: {option?.chassis_number}</span>
      </MenuItem>
    ))
  ) : (
    <MenuItem value="">No options available</MenuItem>
  )}
</Select>

                {/* <InputLabel id="demo-multiple-chip-label">QRCode</InputLabel> */}
                <h3 style={{ fontWeight: "400" }}>QR Code</h3>
                {role == "Admin" ? (
                  <Multiselect
                    style={{ height: "10px" }}
                    placeholder="Type QR ID to get started"
                    Code
                    options={qrList}
                    displayValue="qr_code_id"
                    value={selectedQRCode}
                    onSelect={handleChange}
                    onRemove={handleChange}
                    onSearch={handleQrList}
                    emptyRecordMsg="No Options Available"
                  ></Multiselect>
                ) : null}
                {/* <Select  type="text"
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  
                  multiple
                  value={selectedQRCode}
                  onChange={handleChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  
                  MenuProps={MenuProps}
                >
                  {qrList.map((ele) => (
                    <MenuItem key={ele.qr_code_id} value={ele.qr_code_id} style={getStyles(ele.qr_code_id, selectedQRCode, theme)}>
                      {ele.qr_code_id}
                    </MenuItem>
                  ))}
                </Select> */}

                {role != "Admin" ? (
                  //  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                  <TextField
                    id="standard-basic-vechile_type"
                    label="Enter QR Code ID (Use space if entering multiple values)"
                    name="QR Code"
                    type="text"
                    InputProps={{ min: "1", max: "12" }}
                    onChange={handleEnterQr}
                    style={{ width: "100%", maxWidth: "100%" }}
                  />
                ) : // </Grid>
                null}
              </FormControl>
            </Grid>

            {role == "Admin" ? (
              <Grid item xs={12} sm={6} lg={12}>
                <Button variant="contained" onClick={updateOrder}>
                  Assign
                </Button>
              </Grid>
            ) : null}

            {/* {role !== "Admin" ? (
              <Grid item xs={12} sm={6} lg={12}>
                <Button variant="contained" onClick={updateQrOrder}>
                  Assign
                </Button>
              </Grid>
            ) : null} */}
          </Grid>
        </Card>
      </div>
    </Dashboard>
  );
}
