import React, { useState } from "react";
import adminApiService from "../../services/admiApis.service";
import Dashboard from "../dashboard";
import notify from "../notification/notification";

import { Box, Toolbar, Typography, Paper, Card, Grid, TextField, Button } from "@mui/material";

const Profile = () => {
  const [password, setPassword] = useState({
    email: "",
    newPassword1: "",
    newPassword2: "",
  });

  const [validPassword, setValidPassword] = useState(false);

  // Password Validation
  const passwordValidation = (e) => {
    setPassword({ ...password, newPassword1: e.target.value });
    const regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,50}$/;
    const test = regEx.test(e.target.value);
    if (test) {
      setValidPassword(true);
    } else setValidPassword(false);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  // Change Password Function
  const changePasswordHandler = async (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail(password.email);
    if (!isValidEmail) {
      alert("Please enter form field.");
      return;
    }
    const payload = { password: password.newPassword1, c_password: password.newPassword2, email: password.email };

    if (password.newPassword1 === password.newPassword2 && validPassword && isValidEmail) {
      try {
        const res = await adminApiService.updatePassword(payload);
        if (res.message === "Password updated") {
          notify.sucess("Password updated successfully.");
        }
      } catch (error) {
        alert(error.message);
      }
    } else {
      alert("Please type the password correctly.");
    }
    setPassword({
      email: "",
      newPassword1: "",
      newPassword2: "",
    });
    setValidPassword(false);
  };
  return (
    <>
      <Dashboard>
        <Box className="content-wrapper" mt={2}>
          <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
              }}
            >
              <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                Update Password
              </Typography>
            </Toolbar>

            <Card className="mt-2">
              <Grid container mb={2}>
                <Grid item xs={12} sm={6} lg={12} sx={{ ml: 2, mt: 2 }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus={true}
                    value={password.email}
                    sx={{ width: "30%" }}
                    onChange={(e) => setPassword({ ...password, email: e.target.value })}
                  />
                </Grid>

                <Grid item xs={12} sm={6} lg={12} sx={{ ml: 2, mt: 2 }}>
                  <TextField
                    id="newPassword1"
                    label="New Password"
                    name="newPassword1"
                    type="password"
                    placeholder="New Password"
                    onChange={passwordValidation}
                    value={password.newPassword1}
                    sx={{ width: "30%" }}
                  />
                  {validPassword === false && password.newPassword1 !== "" ? (
                    <p className="text-secondary">Password should be min of 8 digits with at least one special character, number, uppercase and lowercase letter.</p>
                  ) : validPassword === true ? (
                    <p className="text-success">Password format ok.</p>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12} sm={6} lg={12} sx={{ ml: 2, mt: 2 }}>
                  <TextField
                    id="newPassword2"
                    label="ReType New Password"
                    name="name"
                    type="password"
                    placeholder="Retype New Password"
                    onChange={(e) => setPassword({ ...password, newPassword2: e.target.value })}
                    value={password.newPassword2}
                    sx={{ width: "30%" }}
                  />

                  {password.newPassword2 === "" ? (
                    <p></p>
                  ) : password.newPassword1 === password.newPassword2 ? (
                    <p className="text-success">Passwords match.</p>
                  ) : (
                    <p className="text-danger">Passwords doesn't match.</p>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} lg={12} sx={{ ml: 2, mt: 2, mb: 4 }}>
                <Button variant="contained" onClick={changePasswordHandler}>
                  Update Password
                </Button>
              </Grid>
            </Card>
          </Paper>
        </Box>
      </Dashboard>
    </>
  );
};

export default Profile;
