import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { AuthProvider } from './context';
import routes from './routes';
import AppRoutes from './components/app-route';

function App() {
  return (
    <div className="main-container">
      <AuthProvider>
        <Router>
          <Switch>
            {routes.map((route) => (
              <AppRoutes key={route.path} path={route.path} component={route.component} isPrivate={route.isPrivate} exact={route.exact} />
            ))}
          </Switch>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
