import React, { useState, useEffect  } from "react"; 
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";

import Dashboard from "../dashboard";
import adminApiService from "../../services/admiApis.service";
import {useRef} from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import notify from "../notification/notification";


export default function DownloadQRCode() {
  const [quantity, setQuantity] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const handleChange = (event) => {
    setQuantity(event.target.value);
  };

 const disablebtn = false;
  
  // const [inputValue, setInputValue] = useState();
  // const handleUserInput = (e) => {
  //   setInputValue(e.target.value);
  // };
  
  const delay=(time)=>{
    
  return new Promise((resolve,reject)=>{
    setTimeout(()=>{
      resolve(true);
    },time);
  })

  }

  const handleDownlaod = async (event) => {
    if(quantity > 4000){
      alert( "Download Limit is 4000 QRCodes");
      return false;
    }
    
    if (quantity != null) {
    try {
      setIsDownloading(true);
      const res = await adminApiService.generateQR(quantity);
      // console.log(res);
      
      if (res.success) {
        await delay(10000);
        const downloadRes = await adminApiService.makeQRZip();
        const url = downloadRes.response;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute('download',true);
        // link.setAttribute("download", `${Math.floor(Math.random() * 6) + 1}.zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
      setIsDownloading(false);
    } catch (err) {
      console.log(err);
    }
    setQuantity("");
   
   
     
    }
 
  else{
    notify.error("Please enter Quantity");
    return;
  }
  if(!quantity){
    notify.error("Please enter Quantity");
  }
   // window.location.reload();
  };

  const [role ,setRole] = useState("");  

  useEffect(()=> {
  setRole(JSON.parse(localStorage.getItem("adminUser")).role);
  } ,[])



  return (
     <Dashboard>
      <Paper sx={{ mr: 1, ml: 1, mb: 2, mt: 2 }}>
        <Container sx={{ pt: 2, pr: 2, pl: 2, pb: 4 }}>
        { role == "Admin" ?   <Card sx={{ pb: 16 }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
              }}
            >
              <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                Download QR Code
              </Typography>
            </Toolbar>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField  value={quantity} id="standard-basic-qr-code-quantity" label="Enter QR Code Quantity" name="qr-code-quantity" type="number" onChange={handleChange} sx={{ width: "100%" }} />
              </Grid>

              <Grid item xs={12} sm={6} lg={12}>
                <LoadingButton  loading={isDownloading}  disabled={isDownloading} variant="contained" onClick={handleDownlaod}>
                  Download
                </LoadingButton >
              </Grid>
            </Grid>
          </Card> : null }
        </Container>
      </Paper>
    </Dashboard> 
  );
}
