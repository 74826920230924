import { React, useState, useEffect } from "react";
import { IconButton, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar, Typography, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import adminApiService from "../../services/admiApis.service";
import EditIcon from "@mui/icons-material/Edit";
import Pagination from "@mui/material/Pagination";
import { useHistory } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import Dashboard from "../dashboard";
import notify from "../notification/notification";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: "#01579b",
    "& .MuiTableSortLabel-icon": {
      color: "#ffffff !important",
    },
  },
  tabelHeaderCell: {
    color: "#ffffff !important",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: "Code",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "is_active",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "validity_in_month",
    numeric: false,
    disablePadding: false,
    label: "Validity (in months)",
  },
  {
    id: "vechile_type",
    numeric: false,
    disablePadding: false,
    label: "Vechile Type",
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: false,
    label: "Edit",
  },
  {
    id: "delete",
    numeric: false,
    disablePadding: false,
    label: "Delete",
  },
];

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.numeric ? "right" : "left"} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel className={classes.tabelHeaderCell} active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
        Referral Code
      </Typography>
    </Toolbar>
  );
};

export default function RefferalCodeTable() {
  const history = useHistory();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(1);
  const [referralCodes, setReferralCodes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const res = await adminApiService.getAllRefferalCodes();
      if (res.success) {
        setReferralCodes(res.response);
       // console.log("res",res.response)
        const ids = referralCodes.map(item => item.id);
      //  console.log(ids);
      } else {
        setReferralCodes([]);
      }
    }

    fetchData().catch(console.error);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleUpdateReferral = (event, updateReferral) => {
    event.stopPropagation();
    history.push(`/updateReferral/${updateReferral.code}`);
  };

  const handleDelete = async (row, event) => {
   // console.log("id", row.id);
  
  //   const data = {
  //     referral_code_id: referralCodes.id, // Use the correct property name here
  //   };
  // console.log("data",data);
    const res = await adminApiService.deleteReferralCode(row.id);
  
    if (res.success===true) {
      notify.sucess(res.message);
      // window.location.reload();
      setTimeout(() => {
        window.location.reload();
         
        }, 5000);
    } else {
      notify.error(res.message);
    }
  };

  return (
    <>
      <Dashboard>
        {referralCodes && referralCodes.length > 0 ? (
          <Box className="content-wrapper" mt={2}>
            <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
              <EnhancedTableToolbar />
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead order={order} orderBy={orderBy} onSelectAllClick={() => {}} onRequestSort={handleRequestSort} rowCount={referralCodes.length} />
                  <TableBody>
                    {stableSort(referralCodes, getComparator(order, orderBy)).map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow tabIndex={-1} key={row.id}>
                          <TableCell align="left" id={labelId}>
                            {row.code}
                          </TableCell>
                          <TableCell align="left">{row.price}</TableCell>
                          <TableCell align="left">{row.is_active ? "Active" : "Inactive"}</TableCell>
                          <TableCell align="left">{row.validity_in_month}</TableCell>
                          <TableCell align="left">{row.vechile_type}</TableCell>
                          <TableCell align="left" onClick={(event) => handleUpdateReferral(event, row)}>
                            <IconButton>
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell align="left" >   
                           <IconButton key={row.id} onClick={(event) => handleDelete(row, event)}  >
                             <DeleteIcon   />
                           </IconButton>
                         </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{
                  pt: 2,
                  pb: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></Box>
            </Paper>
          </Box>
        ) : null}
      </Dashboard>
    </>
  );
}
