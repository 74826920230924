import { React, useState, useEffect } from "react";
import adminApiService from "../../services/admiApis.service";
import { Box, Toolbar, Typography, Container, Card, Grid, TextField, Paper, Button } from "@mui/material";

import Dashboard from "../dashboard";

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
        Create Order
      </Typography>
    </Toolbar>
  );
};

export default function CreateOrder() {
  const handleChange = (event) => {};

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Dashboard>
        <Box className="content-wrapper" mt={2}>
          <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
            <EnhancedTableToolbar />
            <Card className="mt-2">
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField id="standard-basic" label="Name" name="name" type="text" onChange={handleChange} sx={{ width: "100%" }} />
                  </Grid>

                  {/* Phone Number */}
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField id="standard-basic" label="Phone Number" name="phone_number" type="tel" onChange={handleChange} sx={{ width: "100%" }} />
                  </Grid>

                  {/* Email Id */}
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField id="standard-basic" label="Email" name="email" type="email" onChange={handleChange} sx={{ width: "100%" }} />
                  </Grid>

                  {/* Address */}
                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField id="standard-basic" name="building_name" label="Buiilding Name" type="text" onChange={handleChange} sx={{ width: "100%" }} />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField id="standard-basic" name="area" label="Area" type="text" onChange={handleChange} sx={{ width: "100%" }} />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField id="standard-basic" name="district" label="District" type="text" onChange={handleChange} sx={{ width: "100%" }} />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField id="standard-basic" name="state" label="State" type="text" onChange={handleChange} sx={{ width: "100%" }} />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField id="standard-basic" name="pincode" label="Pin Code" type="number" onChange={handleChange} sx={{ width: "100%" }} />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField id="standard-basic" name="address" label="Address" type="text32" onChange={handleChange} sx={{ width: "100%" }} />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3} sx={{ ml: 2, mt: 2 }}>
                    <TextField id="standard-basic" name="total_cost" label="Total Cost" type="number" onChange={handleChange} sx={{ width: "100%" }} />
                  </Grid>
                </Grid>
                {/* <hr /> */}
                <Typography align="center" sx={{ mt: 2, mb: 2 }}>
                  <Button type="submit" variant="contained" color="primary" size="large">
                    Create Order
                  </Button>
                </Typography>
              </form>
            </Card>
          </Paper>
        </Box>
      </Dashboard>
    </>
  );
}
