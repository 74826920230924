import { React, useState, useEffect } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar, Typography, Paper, TablePagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import adminApiService from "../../services/admiApis.service";

import Dashboard from "../dashboard";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: "#01579b",
    "& .MuiTableSortLabel-icon": {
      color: "#ffffff !important",
    },
  },
  tabelHeaderCell: {
    color: "#ffffff !important",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "qr_code", numeric: false, disablePadding: false, label: "QR Code", isSort: true },
  { id: "call_status", numeric: false, disablePadding: false, label: "Status" },
  {
    id: "direction",
    numeric: false,
    disablePadding: false,
    label: "Direction",
  },
  {
    id: "call_type",
    numeric: false,
    disablePadding: false,
    label: "Call Type",
  },
  {
    id: "digits",
    numeric: false,
    disablePadding: false,
    label: "Digits",
    isSort: true,
  },
  {
    id: "call_from",
    numeric: false,
    disablePadding: false,
    label: "Call From",
  },
  {
    id: "call_to",
    numeric: false,
    disablePadding: false,
    label: "Call To",
  },
  {
    id: "customer_number",
    numeric: false,
    disablePadding: false,
    label: "Customer No.",
  },
  {
    id: "emergency_number",
    numeric: false,
    disablePadding: false,
    label: "Emergency No.",
  },
  {
    id: "dial_call_duration",
    numeric: false,
    disablePadding: false,
    label: "Call Duration",
  },
  {
    id: "latitude_longitude",
    numeric: false,
    disablePadding: false,
    label: "Location (lat, lng)",
  },
  {
    id: "start_time",
    numeric: false,
    disablePadding: false,
    label: "Start Time",
    isSort: true,
  },
];

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.numeric ? "right" : "left"} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.isSort ? (
              <>
                <TableSortLabel className={classes.tabelHeaderCell} active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                </TableSortLabel>
              </>
            ) : (
              <span className="table-headCell" style={{ color: "#fff" }}>
                {headCell.label}
              </span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EmergencyCallHistory() {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("start_time");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emergencyCallHistory, setCallHistory] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchedVal, setSearchedVal] = useState("");

  useEffect(() => {
    async function fetchData() {
      const res = await adminApiService.getEmergencyCallHistory();
      if (res.success) {
        setCallHistory(res.response);
        setFilterData(res.response);
      } else {
        setCallHistory([]);
        setFilterData([]);
      }
    }

    fetchData().catch(console.error);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setPage(0);
    setSearchedVal(event.target.value);
    const filterData = emergencyCallHistory.filter((ele) => {
      return (
        ele.digits.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()) || ele.dial_whom_number.toString().toLowerCase().includes(event.target.value.toString().toLowerCase())
      );
    });
    setFilterData(filterData);
  };

  return (
    <>
      <Dashboard>
        {emergencyCallHistory && emergencyCallHistory.length > 0 ? (
          <Box className="content-wrapper" mt={2}>
            <Paper sx={{ mr: 1, ml: 1, mb: 2 }}>
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  mb: 2,
                }}
              >
                <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                  Emergency Call History
                </Typography>
                <label htmlFor="search">Search</label>
                <input className="search-input-box" id="search" type="text" onChange={handleSearch} placeholder="Search by digits, customer No." />
              </Toolbar>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead order={order} orderBy={orderBy} onSelectAllClick={() => {}} onRequestSort={handleRequestSort} rowCount={emergencyCallHistory.length} />
                  <TableBody>
                    {stableSort(filterData, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow tabIndex={-1} key={row.id}>
                            <TableCell align="left">{row.qr_code}</TableCell>
                            <TableCell align="left">{row.call_status}</TableCell>
                            <TableCell align="left">{row.direction}</TableCell>
                            <TableCell align="left">{row.call_type}</TableCell>
                            <TableCell align="left">{row.digits}</TableCell>
                            <TableCell align="left">{row.call_from}</TableCell>
                            <TableCell align="left">{row.call_to}</TableCell>
                            <TableCell align="left">{row.customer_number}</TableCell>
                            <TableCell align="left">{row.emergency_number}</TableCell>
                            <TableCell align="left">{row.dial_call_duration}</TableCell>
                            <TableCell align="left">{`${row.latitude ? row.latitude : ""} ${row.longitude ? row.longitude : ""}`}</TableCell>
                            <TableCell align="left">{moment(row.start_time).utc().format("DD/MM/YYYY hh:mm:ss A")}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filterData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        ) : null}
      </Dashboard>
    </>
  );
}
