import SignIn from "../components/SignIn";
import DownloadQRCode from "../components/downloadqrcode";
import Order from "../components/order";
import ViewOrder from "../components/view-order";
import Error404 from "../components/error404";
import CreateOrder from "../components/create-order";
import RefferalCodeTable from "../components/referral-code";
import QRListTable from "../components/qr-list";
import UpdateOrder from "../components/update-order";
import AddRefferalCode from "../components/add-refferalCode";
import AddVehicle from "../components/add-vehicle";
import UpdateReferral from "../components/update-referral";
import UserDetails from "../components/user-details";
import SmsHistory from "../components/sms-history";
import CallHistory from "../components/call-history";
import AssignQRCode from "../components/assign-QRCode";
import SmsContent from "../components/sms-content";
import QRDetails from "../components/qr-details";
import Profile from "../components/profile";
import VehicleType from "../components/vehicle-type";
import UpdateVehicleType from "../components/update-vehicleType";
import EmergencyCallHistory from "../components/emergencyCall-history";
import ReplacementOrder from "../components/replacement-order";
import UpdateReplacementOrder from "../components/update-replacement-orderStatus";
import AssignReplacementQRCode from "../components/assign-replacement-qrCode";
import ViewReplacementOrder from "../components/view-replacement-order";
import TrackReplacementOrder from "../components/fetch-vehicle";
import CustomerOrder from "../components/customer-orders";
import CustomerDetailsList from "../components/customer-details-list";
import UpdateCustomerDetails from "../components/update-customer-details";
import UploadCsv from "../components/upload-cvs";
import ViewOrderCustomer from "../components/view-order-customer";
const routes = [
  {
    path: "/login",
    component: SignIn,
    isPrivate: false,
    exact: false,
  },
  {
    path: "/",
    component: DownloadQRCode,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/order",
    component: Order,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/customerOrder",
    component: CustomerOrder,
    isPrivate: true,
    exact: true,
  },
 
  {
    path: "/customerDetailsList",
    component:  CustomerDetailsList,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/updateCustomerDetails/:id",
    component:  UpdateCustomerDetails,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/replacementOrder",
    component: ReplacementOrder,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/viewOrder/:orderNo",
    component: ViewOrder,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/viewOrderCustomer/:orderNo",
    component: ViewOrderCustomer,
    isPrivate: true,
    exact: true,
  },
  
  {
    path: "/viewReplacementOrder/:orderNo",  
    component: ViewReplacementOrder,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/fetchVehicleDetails", 
    component: TrackReplacementOrder,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/createOrder",
    component: CreateOrder,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/referralCodes",
    component: RefferalCodeTable,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/qrList",
    component: QRListTable,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/viewQRDetails/:qrCode",
    component: QRDetails,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/updateOrder/:orderNo",
    component: UpdateOrder,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/updateReplacementOrder/:orderNo",
    component: UpdateReplacementOrder,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/assignQRCode",
    component: AssignQRCode,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/assignReplacementQRCode",
    component: AssignReplacementQRCode,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/updateReferral/:referralCode",
    component: UpdateReferral,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/addReferral",
    component: AddRefferalCode,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/addVehicle",
    component: AddVehicle,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/userDetails/:qrCode",
    component: UserDetails,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/smsHistory",
    component: SmsHistory,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/smsContent",
    component: SmsContent,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/callHistory",
    component: CallHistory,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/emergencyCallHistory",
    component: EmergencyCallHistory,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/error",
    component: Error404,
    isPrivate: false,
    exact: false,
  },
  {
    path: "/profile",
    component: Profile,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/vehicleType",
    component: VehicleType,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/updateVehicle/:vehicleId",
    component: UpdateVehicleType,
    isPrivate: true,
    exact: true,
  },
  {
    path: "/uploadCsv",
    component: UploadCsv,
    isPrivate: true,
    exact: true,
  },
  
];

export default routes;
