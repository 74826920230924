import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Toolbar from "@mui/material/Toolbar";
import Card from "@mui/material/Card";
import InputLabel from "@mui/material/InputLabel";

import notify from "../notification/notification";

import Dashboard from "../dashboard";
import adminApiService from "../../services/admiApis.service";

import { useHistory, useParams } from "react-router-dom";

export default function UpdateReferral() {
  const [status, setStatus] = useState(true);
  const params = useParams();
  const history = useHistory();

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const updateReferral = async (event) => {
    if (status === null) {
      alert("Please select status");
      return;
    }
    const data = {
      referral_code: params.referralCode,
      status: status,
    };

    try {
      const res = await adminApiService.updateReferralCodeStatus(data);
      if (res.status === "success") {
        notify.sucess("Updated Referral Status");
        history.push("/referralCodes");
      }
    } catch (err) {
      console.log(err);
      notify.error("Updated Referral Status Failed");
    }
  };

  return (
    <Dashboard>
      <div className="update-referral-container">
        <Card sx={{ pb: 16 }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
              Update Referral Status
            </Typography>
          </Toolbar>
          <Grid container spacing={3} sx={{ pl: 2 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                <Select value={status} onChange={handleChange} label="Select Status" inputProps={{ "aria-label": "Without label" }}>
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>In Active</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} lg={12}>
              <Button variant="contained" onClick={updateReferral}>
                Update
              </Button>
            </Grid>
          </Grid>
        </Card>
      </div>
    </Dashboard>
  );
}
