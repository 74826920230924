import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";

import Dashboard from "../dashboard";
import adminApiService from "../../services/admiApis.service";
import notify from "../notification/notification";

import { useHistory, useParams } from "react-router-dom";

export default function UpdateVehicleType() {
  const history = useHistory();
  const [amount, setAmount] = useState("");
  const [validityInMonth, setValidityInMonth] = useState("");
  const params = useParams();

  const updateVehicleType = async (event) => {
    if (!amount || !validityInMonth) {
      alert("Please Enter amount or validity");
      return;
    }
    const data = {
      id: params.vehicleId,
      amount: amount,
      validity_in_month: validityInMonth,
    };
    try {
      const res = await adminApiService.updateVehicleType(data);
      if (res.success) {
        notify.sucess("Updated Vehicle Type");
        history.push("/vehicleType");
      }
    } catch (err) {
      notify.error("Updated Vehicle Type Failed");
      console.log(err);
    }
  };

  const handleAmount = (event) => {
    setAmount(event.target.value);
  };

  const handleValidity = (event) => {
    setValidityInMonth(event.target.value);
  };

  return (
    <Dashboard>
      <div className="update-order-container">
        <Card sx={{ pb: 16 }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
              Update Vehicle Type
            </Typography>
          </Toolbar>
          <Grid container spacing={3} sx={{ pl: 2 }}>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="standard-basic-amount" label="Amount" name="amount" type="number" InputProps={{ min: "1", max: "12" }} onChange={handleAmount} sx={{ width: "100%" }} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="standard-basic-validity_in_month"
                label="Validity (in months)"
                name="validity_in_month"
                type="number"
                InputProps={{ min: "1", max: "12" }}
                onChange={handleValidity}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={12}>
              <Button variant="contained" onClick={updateVehicleType}>
                Update
              </Button>
            </Grid>
          </Grid>
        </Card>
      </div>
    </Dashboard>
  );
}
