import React, { useState } from 'react';
import Papa from 'papaparse';
import Dashboard from "../dashboard";
import notify from "../notification/notification";
import "./uploadCsv.css";
import { Paper } from '@mui/material';

function CsvUploader() {
  const [file, setFile] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (file) {
      try {
        const text = await file.text();
        const { data } = Papa.parse(text, { header: true,
          skipEmptyLines: 'greedy', // Skip lines that have only white spaces
        
          transformHeader: (header) => header.trim(), // Trim headers
          transform: (value) => {
            if (typeof value === 'string') {
              return value.trim(); // Trim values
            }
            return value;
          },
      });

        if (data && data.length > 0) {
          const apiKey = 'HD_KEY@123#AWFTYU';

          // Create an array to hold the formatted user data
          const userDataArray = [];

          data.forEach((rowData) => {
            // Customize this part to format each row as needed
            const formattedData = {
              name: rowData.Name,
              email: rowData.Email,
              phone_number: rowData['Phone_number'],
              building_no: rowData.Building_no,
              building_name: rowData.Building_name,
              street: rowData.Street,
              area: rowData.Area,
              district: rowData.District,
              agent_id: rowData.Agent_id,
              state: rowData.State,
              pincode: rowData.Pincode,
              address: rowData.Address,
              referral_code:rowData.Referral_code ,
              vehicle_type: rowData.Vehicle_type,
              vehicle_no: rowData.Vehicle_no,
              chassis_number:rowData.Chassis_number ,
              qr_code_id: rowData.Qr_code_id,
              blood_group:rowData.Blood_group ,
              emergency_contact1: rowData.Emergency_contact1,
              relation1: rowData.Relation1,
              emergency_contact2:rowData.Emergency_contact2 ,
              relation2: rowData.Relation2,
              emergency_contact3:rowData.Emergency_contact3 ,
              relation3:rowData.Relation3 ,
              emergency_contact4:rowData.Emergency_contact4 ,
              relation4:rowData.Relation4 ,
              emergency_contact5:rowData.Emergency_contact5 ,
              relation5:rowData.Relation5 ,
              gender: rowData.Gender,
            };
            userDataArray.push(formattedData);
          });

          const formData = new FormData();
          const fileData =JSON.parse(JSON.stringify(userDataArray));
          const requestBody = JSON.stringify({ user_data: fileData });
          // Append the formatted user data as 'user_data' in formData
          formData.append('user_data',userDataArray);
       //   console.log("402 line b4 api call")
           const response = await fetch('https://admin.rq.highwaydelite.com/v1/activate-rqr-by-csv-upload', {   //prod path
        // const response = await fetch('http://3.111.76.3/v1/activate-rqr-by-csv-upload', {                        //test path
            method: 'POST',
            headers: {
              'x-api-key': apiKey,
              'Content-Type': 'application/json',
            },
            body: requestBody,
          });
          
          if (response.ok) {
            const responseData = await response.json(); // Parse the response body as JSON
            if (responseData.success === true) {
              // Handle success
              notify.sucess(responseData.message);
            } else {
              // Handle error
              notify.error(responseData.message);
            }
          } else {
            console.error('API request failed with status', response.status);
          }
        } 
      } catch (error) {
        console.error('Error', error);
      }
    }
  };
  const downloadSampleCSV = () => {
    const csvContent = `Name,Email,Phone_number,Building_no,Building_name,Street,Area,District,Agent_id,State,Pincode,Address,Referral_code,Vehicle_type,Vehicle_no,Chassis_number,Qr_code_id,Blood_group,Emergency_contact1,Relation1,Emergency_contact2,Relation2,Emergency_contact3,Relation3,Emergency_contact4,Relation4,Emergency_contact5,Relation5,Gender`;
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sample.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div>
      <Dashboard>
      <Paper sx={{ mr: 1, ml: 1, mb: 4, mt: 2 ,paddingBottom: '63px'}}>
        <div className='mainDiv'>
        <form  onSubmit={handleSubmit}>
          <div className='mainTop'> 
          <input className='choosefile' type="file" accept=".csv" onChange={handleFileChange} />  
          <button className='downloadSample' onClick={downloadSampleCSV}>Download Sample CSV File</button>
          </div>
       
        <button className='uploadbtn'  type="submit">Upload CSV</button>
        
      </form>
        </div>
      </Paper>
      </Dashboard>
    </div>
  );
}

export default CsvUploader;