import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notify = {
  sucess(message) {
    return toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  },
  error(message) {
    return toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  },
  warning(message) {
    return toast.warn(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  },
};

export default notify;
